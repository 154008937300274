import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

import {
  ContainerMaxWidths,
  GridBreakpoints,
  GridProps,
  WindowSize,
} from '@/types/bootstrap-types';
import { createLog } from '@/utils';

const log = createLog(`useGridProps`);

export type { ContainerMaxWidths, GridBreakpoints, GridProps, WindowSize };

export const getGridBreakpoint = (windowSize: WindowSize): GridBreakpoints => {
  const { width } = windowSize;
  if (GridBreakpoints.XS <= width && width < GridBreakpoints.SM)
    return GridBreakpoints.XS;
  if (GridBreakpoints.SM <= width && width < GridBreakpoints.MD)
    return GridBreakpoints.SM;
  if (GridBreakpoints.MD <= width && width < GridBreakpoints.LG)
    return GridBreakpoints.MD;
  if (GridBreakpoints.LG <= width && width < GridBreakpoints.XL)
    return GridBreakpoints.LG;
  if (GridBreakpoints.XL <= width) return GridBreakpoints.XL;
  return GridBreakpoints.UNKNOWN;
};

export const getContainerMaxWidth = (windowSize: WindowSize): ContainerMaxWidths => {
  const { width } = windowSize;
  if (ContainerMaxWidths.XS <= width && width < ContainerMaxWidths.SM)
    return ContainerMaxWidths.XS;
  if (ContainerMaxWidths.SM <= width && width < ContainerMaxWidths.MD)
    return ContainerMaxWidths.SM;
  if (ContainerMaxWidths.MD <= width && width < ContainerMaxWidths.LG)
    return ContainerMaxWidths.MD;
  if (ContainerMaxWidths.LG <= width && width < ContainerMaxWidths.XL)
    return ContainerMaxWidths.LG;
  if (ContainerMaxWidths.XL <= width) return ContainerMaxWidths.XL;
  return ContainerMaxWidths.UNKNOWN;
};

const useGridProps = (): GridProps => {
  const windowSize = useWindowSize();
  const { gridBreakpoint, containerMaxWidth, isDesktop, isMobile } = useMemo(() => {
    const lContainerMaxWidth = getContainerMaxWidth(windowSize);
    const lGridBreakpoint = getGridBreakpoint(windowSize);

    const gridProps = {
      containerMaxWidth: lContainerMaxWidth,
      gridBreakpoint: lGridBreakpoint,
      isDesktop: lGridBreakpoint > GridBreakpoints.MD,
      isMobile: lGridBreakpoint < GridBreakpoints.MD,
    };
    // log.debug(`gridProps: ${JSON.stringify(gridProps)}`);

    return gridProps;
  }, [windowSize]);

  return { containerMaxWidth, gridBreakpoint, isDesktop, isMobile };
};

export default useGridProps;

import chalk, { Chalk } from 'chalk';
import loglevel, { Logger, RootLogger } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import { useEffect } from 'react';

import { Configs, NODE_ENV, NodeEnv } from '_/configs';

import { useStateL } from '@/utils';

const colors: Record<string, Chalk> = {
  TRACE: chalk.gray,
  DEBUG: chalk.magenta,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red,
};

const createRootLogger = (log: RootLogger, nodeEnv?: NodeEnv): RootLogger => {
  const localNodeEnv = nodeEnv || NODE_ENV;

  let lvl: loglevel.LogLevelDesc = `info`;
  if (localNodeEnv === NodeEnv.PROD) lvl = `warn`;
  else if (localNodeEnv === NodeEnv.DEVELOP) lvl = `debug`;
  else if (localNodeEnv === NodeEnv.TEST) lvl = `info`;
  else throw new Error(`nodeEnv is unknown value (${localNodeEnv})`);

  if (Configs.isVerboseMode()) {
    lvl = `trace`;
    log.debug(`set loglevel to '${lvl}'`);
    log.debug(`loglevel: ${log.getLevel()}`);
  }

  log.setLevel(lvl);

  return log;
};

const createPrefixedLogger = (nodeEnv?: NodeEnv, logName?: string): Logger => {
  const rootLogger = createRootLogger(loglevel, nodeEnv);
  prefix.reg(rootLogger);
  rootLogger.enableAll();

  prefix.apply(rootLogger.getLogger(logName || `root`), {
    format(level, name, timestamp) {
      return `${chalk.gray(`[${timestamp}]`)} [${colors[level.toUpperCase()](
        level.toUpperCase().slice(0, 6).padEnd(5),
      )}] [${chalk.green(`${name}`)}]`;
    },
  });

  prefix.apply(rootLogger.getLogger(`critical`), {
    format(level, name, timestamp) {
      return chalk.red.bold(
        `[${timestamp}] [${level.toUpperCase().slice(0, 6).padEnd(5)}] [${name}]`,
      );
    },
  });

  const log = rootLogger.getLogger(logName || `root`);
  if (Configs.isVerboseMode()) {
    log.debug(`prefixedLogger created with name '${logName || `root`}'`);
  }

  return log;
};

export const createLog = (name?: string): Logger => createPrefixedLogger(NODE_ENV, name);

// export const useLog = (name: string): Logger => {
//   return useMemo(() => createLog(name), [name]);
// };

export const useLog = (name: string): Logger => {
  const [log, setLog] = useStateL(createLog(name), `log`);
  useEffect(() => {
    setLog(createLog(name));
  }, [name, setLog]);

  return log;
};

import log from 'loglevel';

import { NodeEnv } from '@/types';

export { NodeEnv };

export const getNodeEnv = (): NodeEnv => <NodeEnv>process.env.NODE_ENV || NodeEnv.DEVELOP;
export const NODE_ENV = getNodeEnv();
log.info(`NODE_ENV: ${NODE_ENV}`);

export const __DEV__ = NODE_ENV === NodeEnv.DEVELOP;
log.debug(`__DEV__: ${__DEV__}`);
export const __VERBOSE__ = `true` === process.env.VERBOSE;
log.debug(`__VERBOSE__: ${__VERBOSE__}`);

export const PATH_ROOT = ``;
export const PATH_ASSETS = `${PATH_ROOT}/assets`;
export const PATH_ASSETS_IMAGES = `${PATH_ASSETS}/images`;
export const PATH_ASSETS_IMAGES_SECTIONS = `${PATH_ASSETS_IMAGES}/sections`;
export const PATH_ASSETS_IMAGES_BG = `${PATH_ASSETS_IMAGES}/bg`;
export const PATH_ASSETS_IMAGES_LOGO = `${PATH_ASSETS_IMAGES}/logo`;
export const PATH_ASSETS_IMAGES_SVG = `${PATH_ASSETS_IMAGES}/svg`;

import React from 'react';
import { UseTransitionProps, config, useTransition } from '@react-spring/web';

export interface SvgTransitionProps {
  // REQUIRED
  id: string;
  Svg1: any;
  Svg2: any;
  toggle: boolean;

  // OPTIONAL
  className?: string;
  springProps?: UseTransitionProps;
  style?: React.CSSProperties;
}

const SvgTransition = ({
  // REQUIRED
  id,
  Svg1,
  Svg2,
  toggle,

  // OPTIONAL
  className,
  springProps = {
    from: { position: `absolute`, opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 100,
    config: config.molasses,
  },
  style = {},
}: SvgTransitionProps) => {
  const transitions = useTransition(toggle, springProps);

  return (
    <>
      {transitions(({ opacity }: any, alt: boolean) => {
        const svgProps = {
          id: alt ? `${id}Alt` : id,
          className,
          varOpacity: opacity,
          style,
        };
        return alt ? <Svg2 {...svgProps} /> : <Svg1 {...svgProps} />;
      })}
    </>
  );
};

export default SvgTransition;

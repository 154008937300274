import { animated, useTrail } from '@react-spring/web';
import cn from 'classnames';
import React from 'react';

import styles from './Trail.module.scss';

export interface TrailProps {
  // REQUIRED
  id: string;
  elements: Array<React.ReactElement> /* TODO: | Array<string> */;
  open: boolean;

  // OPTIONAL
  defaultStyle?: boolean;
  delay?: number;
  duration?: number;
  onClick?: () => void;
}

const Trail = ({
  // REQUIRED
  id,
  elements,
  open,

  // OPTIONAL
  defaultStyle = false,
  delay = 0,
  duration = 250,
}: TrailProps) => {
  const wrapProps = { id, elements, open, delay, duration };

  // TODO: when elements are string convert them to Array<JSX.Element>
  // function createTrailItems(rawData: Array<string>): any {
  //   return rawData.map((item) => <span>{item}</span>);
  // }
  // const items = useMemo(() => createTrailItems(data), [data]);
  // const [items, setItems] = useStateL(createTrailItems(data), `items`);

  const trail = useTrail(elements.length, {
    config: { mass: 5, tension: 2000, friction: 200, duration },
    from: { opacity: 0, x: 20, height: 0 },
    to: {
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 110 : 0,
    },
    delay,
  });

  return (
    <div
      className={cn(defaultStyle ? styles.defaultTrailsMain : styles.trailsMain)}
      {...wrapProps}
    >
      <div>
        {trail.map(({ x, height, ...style }, index) => (
          <animated.div
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${index}`}
            className={`${defaultStyle ? styles.defaultTrailsText : styles.trailsText}`}
            style={{
              ...style,
              transform: x.to((x2: number) => `translate3d(0,${x2}px,0)`),
            }}
          >
            <animated.div style={{ height }}>{elements[index]}</animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Trail;

export const motionBasicProps = {
  initial: `initial`,
  exit: `exit`,
};

export const visibleInViewProps = {
  ...motionBasicProps,
  whileInView: `enter`,
  // onViewportEnter: `enter`,
  // onViewportExit: `exit`,
  viewport: {
    margin: `-50px 0px`,
    once: false,
  },
};

export const fadeInProps = {
  slow: {
    transition: { delay: 0.3, duration: 0.6 },
  },
  normal: {
    transition: { delay: 0.2, duration: 0.4 },
  },
};

export const fadeUpProps = {
  variants: {
    initial: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  },
};

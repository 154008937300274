import { Heading, HeadingProps, forwardRef } from '@chakra-ui/react';
import { MotionProps, isValidMotionProp, motion } from 'framer-motion';
import React from 'react';

export type MotionHeadingProps = HeadingProps | MotionProps;

const MotionHeadingRef = forwardRef((props, ref) => {
  const chakraProps = Object.fromEntries(
    // do not pass framer props to DOM element
    Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
  );
  return <Heading ref={ref} {...chakraProps} />;
});

const MotionHeading = motion(MotionHeadingRef);

MotionHeading.displayName = `MotionHeading`;

export default MotionHeading;

import { Box, Flex, chakra } from '@chakra-ui/react';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import { NODE_ENV, NodeEnv } from '_/configs';

import { TextUnderlineDivider } from '@/components';
import { Container } from '@/layout';
import { createLog, useGridProps, useStateL } from '@/utils';

import styles from './content-section.module.scss';

import type { BoxProps, ContainerProps, FlexProps } from '@chakra-ui/react';
import type { CSSProperties, ReactNode } from 'react';
import type { TextUnderlineProps } from '@/components';

const log = createLog(`ContentSection`);

export interface ContentSectionProps {
  // OPTIONAL
  handleEnter?: (args: Waypoint.CallbackArgs) => void;
  handleLeave?: (args: Waypoint.CallbackArgs) => void;
  subtitle?: ReactNode | string;
  title?: ReactNode | string;
  waypointBottomOffset?: string | number;
  waypointTopOffset?: string | number;

  // chakra-ui
  // bg?: BoxProps['bg'];
  containerProps?: ContainerProps;
  // description?: string;
  isActive?: boolean;
  maxW?: BoxProps['maxW'];
  pb?: BoxProps['pb'];
  pt?: BoxProps['pt'];
  py?: BoxProps['py'];
  sectionProps?: FlexProps;
  // title?: string;
  titleTextUnderlineProps?: TextUnderlineProps;

  // sectionProps
  id: string;

  // OPTIONAL
  className?: string;
  bg?: string;
  bgColor?: `dark` | `gray` | `primary` | `secondary`;
  bgFixed?: boolean;
  bgImg?: string;
  bgDarken?: number;
  bgPos?: CSSProperties['backgroundPosition'];
  bgRepeat?: CSSProperties['backgroundRepeat'];
  bgSize?: CSSProperties['backgroundSize'];
  color?: `light`;
  contentTitle?: ReactNode;
  isCentered?: boolean;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  isOverflowVisible?: boolean;
  sectionPaddingBottom?: number | string;
  sectionPaddingTop?: number | string;
  sectionStyle?: CSSProperties;
  titleSeparator?: `default` | `dark` | `none`;
  titleStyle?: CSSProperties;
}

const ContentSection = ({
  children,

  // OPTIONAL
  handleEnter,
  handleLeave,
  subtitle,
  title,
  waypointBottomOffset = `-30%`,
  waypointTopOffset = `5%`,

  // chakra-ui
  // bg,
  containerProps,
  // description,
  isActive = true,
  maxW = `1500px`,
  pb = { base: 24, md: 32 },
  pt = { base: 24, md: 32 },
  py = { base: 24, md: 32 },
  sectionProps,
  // title,
  titleTextUnderlineProps,

  // sectionProps
  id,

  // OPTIONAL
  className,
  bg,
  bgColor,
  bgFixed = false,
  bgImg,
  bgDarken = 0,
  bgPos = `center center`,
  bgRepeat = `no-repeat`,
  bgSize = `cover`,
  color,
  contentTitle,
  isCentered = false,
  isFullHeight = false,
  isFullWidth = false,
  isOverflowVisible = false,
  sectionPaddingBottom,
  sectionPaddingTop,
  sectionStyle,
  titleSeparator = `default`,
  titleStyle,
}: React.PropsWithChildren<ContentSectionProps>) => {
  const { isMobile } = useGridProps();
  const [titleColor, setTitleColor] = useStateL(undefined, `titleColor`);
  const [underlineColor, _setUnderlineColor] = useStateL(`brand.300`, `underlineColor`);

  useEffect(() => {
    if (bgColor === `dark` || bgImg) {
      setTitleColor(`white`);
    } else {
      setTitleColor(`gray.700`);
    }
  }, [bgColor, bgImg, setTitleColor]);

  // useEffect(() => {
  //   if (titleSeparator === `dark`) {
  //     setUnderlineColor(`brand.300`);
  //   } else {
  //     setUnderlineColor(`brand.300`);
  //   }
  // }, [titleSeparator, setUnderlineColor]);

  const localSectionStyle = useMemo<CSSProperties>(() => {
    const style: CSSProperties = {};

    style.background = ``;
    if (bg) {
      style.background = bg;
    }
    // } else if (bgImg) {
    if (bgDarken > 0) {
      style.background = `linear-gradient(
          to bottom,
          rgba(23, 24, 32, ${bgDarken}),
          rgba(23, 24, 32, ${bgDarken})
        ), `;
    }
    if (bgImg) style.background += `url('${bgImg}')`;
    // style.background += `${bgRepeat} ${bgPos}`;

    style.backgroundSize = bgSize;
    style.backgroundPosition = bgPos;
    style.backgroundRepeat = bgRepeat;
    if (bgFixed) style.backgroundAttachment = `fixed`;
    // }

    if (style.background === ``) delete style.background;

    return style;
  }, [bg, bgImg, bgDarken, bgRepeat, bgPos, bgSize, bgFixed]);

  // const [isActiveLocal, setActiveLocal] = useStateL(isActive, `isActiveLocal`);
  // useEffect(() => {
  //   setActiveLocal(isActive);
  // }, [isActive, setActiveLocal]);

  const localHandleEnter = useCallback(
    (args: Waypoint.CallbackArgs) => {
      if (NODE_ENV !== NodeEnv.PROD) log.debug(`=> ENTER '${id}'`);
      // if (isMobile) setActiveLocal(true);
      if (handleEnter) handleEnter(args);
    },
    [handleEnter, id],
  );
  const localHandleLeave = useCallback(
    (args: Waypoint.CallbackArgs) => {
      if (NODE_ENV !== NodeEnv.PROD) log.debug(`   LEAVE '${id}' =>`);
      // if (isMobile) setActiveLocal(false);
      if (handleLeave) handleLeave(args);
    },
    [handleLeave, id],
  );

  return (
    <Flex
      as="section"
      align="center"
      justify="center"
      id={id}
      bg={bg}
      py={py}
      pb={pb}
      pt={pt}
      mx="auto"
      {...sectionProps}
      className={cn(className, styles.contentSection, {
        [styles.bgDark]: bgColor === `dark`,
        [styles.bgGray]: bgColor === `gray`,
        [styles.bgPrimary]: bgColor === `primary`,
        [styles.bgSecondary]: bgColor === `secondary`,
        [styles.colorLight]: color === `light`,
        [styles.isCentered]: isCentered,
        [styles.isFullHeight]: isFullHeight && !isMobile,
        // [styles.isFullWidth]: isFullWidth,
      })}
      style={{
        ...localSectionStyle,
        paddingBottom: sectionPaddingBottom,
        paddingTop: sectionPaddingTop,
        ...sectionStyle,
      }}
    >
      <Waypoint
        bottomOffset={waypointBottomOffset}
        onEnter={localHandleEnter}
        onLeave={localHandleLeave}
        topOffset={waypointTopOffset}
      >
        <chakra.div maxW={maxW} py={py} pb={pb} pt={pt} {...containerProps}>
          {title || subtitle ? (
            <Box
              maxW="760px"
              mx="auto"
              textAlign="center"
              mb={14}
              className={styles.titleWrap}
              style={{ ...titleStyle }}
            >
              {title && (
                // <FloatingItem id={`${id}Title`} delay={320} toggle={isActiveLocal}>
                <chakra.h2 textStyle="heading" mb={5}>
                  <TextUnderlineDivider
                    colorLight={titleColor}
                    hide={titleSeparator === `none`}
                    show={titleSeparator !== `none`}
                    underlineColorLight={underlineColor}
                    {...titleTextUnderlineProps}
                  >
                    {title}
                  </TextUnderlineDivider>
                </chakra.h2>
                // </FloatingItem>
              )}
              {subtitle && (
                // <FloatingItem
                //   id={`${id}Subtitle`}
                //   className={styles.subtitle}
                //   delay={360}
                //   toggle={isActiveLocal}
                // >
                <chakra.p textStyle="description">{subtitle}</chakra.p>
                // </FloatingItem>
              )}
            </Box>
          ) : null}
          <div
            className={cn(styles.content, {
              [styles.isFullWidth]: isFullWidth,
              [styles.overflowVisible]: isOverflowVisible,
            })}
          >
            {contentTitle ? (
              <Container className={styles.contentTitle}>{contentTitle}</Container>
            ) : null}
            <Container py={3} className={styles.contentBody}>
              {children}
            </Container>
          </div>
        </chakra.div>
      </Waypoint>
    </Flex>
  );
};

export default ContentSection;

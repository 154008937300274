import { Box, Flex, Icon, Link, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { BiPhone } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { RiInstagramLine, RiKakaoTalkLine } from 'react-icons/ri';

import { BrandLogo, NaverLogoIcon } from '@/components/svgs';
import { t } from '@/utils';

import Container from './container';

import type { IconProps } from '@chakra-ui/react';

export interface FooterLinkProps {
  href: string;

  // OPTIONAL
  fontSize?: IconProps['fontSize'];
  icon?: React.ElementType;
  label?: string;
}

export const FooterLink = ({
  href,

  // OPTIONAL
  icon,
  fontSize = `1.75rem`,
  label = href,
}: FooterLinkProps): JSX.Element => (
  <Link
    display="inline-block"
    href={href}
    aria-label={label}
    isExternal
    _hover={{ color: `brand.500` }}
  >
    {icon ? <Icon as={icon} fontSize={fontSize} /> : <span>{label}</span>}
  </Link>
);

export const footerLinks = [
  {
    icon: BiPhone,
    label: `Phone`,
    href: `tel:` + t(`company.phoneNum`),
  },
  {
    icon: HiOutlineMail,
    label: `Email`,
    href: `mailto:` + t(`company.contactEmail`),
  },
  {
    icon: RiKakaoTalkLine,
    label: `Kakaotalk`,
    href: t(`company.linkToKakaotalk`),
  },
  {
    icon: RiInstagramLine,
    label: `Instagram`,
    href: t(`company.linkToInstagram`),
  },
  {
    icon: NaverLogoIcon,
    label: `NaverBlog`,
    href: t(`company.linkToNaverBlog`),
  },
];

export const Footer = () => (
  <Box
    as="footer"
    py={{ base: 4, md: 8 }}
    bg="gray.800"
    color="white"
    fontSize={{ base: `0.9rem`, md: `1.05rem` }}
    textAlign="left"
  >
    <Container py={8} maxW="1640px">
      <Flex
        alignItems="flex-start"
        flexDir={{ base: `column`, md: `row` }}
        justifyContent="space-between"
      >
        <Stack
          align="center"
          direction="row"
          flex="1 1 0"
          justify="center"
          mb="2rem"
          spacing="12px"
        >
          <BrandLogo
            logoStyleType="light"
            svgProps={{ width: { base: `10rem`, md: `15rem` } }}
          />
        </Stack>
        <VStack
          alignItems="flex-start"
          color="gray.500"
          flex="10 1 0"
          lineHeight="1.2"
          ml={{ base: 0, md: `3.5rem` }}
          mb={{ base: 3, md: 0 }}
        >
          <Text
            color="white"
            mb={{ base: `1rem`, md: `0.6rem` }}
            fontSize={{ base: `0.9rem`, md: `1.1rem` }}
          >
            <Text as="span" display={{ base: `none`, md: `inline` }}>
              {t(`company.brandEN`) + ` - `}
            </Text>
            PROFESSIONAL DIGITAL MARKETING COMPANY
          </Text>
          <Text>
            (주){t(`company.brandKO`)}
            {` `}| 대표: {t(`company.ceoName`)}
            {` `}| Tel:{` `}
            <FooterLink
              href={`tel:${t(`company.phoneNum`)}`}
              label={t(`company.phoneNum`)}
            />
            {` `}| E-mail:{` `}
            <FooterLink
              href={`mailto:${t(`company.contactEmail`)}`}
              label={t(`company.contactEmail`)}
            />
          </Text>
          <Text>
            주소: {t(`company.address1`)} {t(`company.address2`)}
          </Text>
          <Text>
            사업자등록번호: {t(`company.registrationNum`)}
            {` `}| 통신판매업신고증: {t(`company.registrationNumMailOrder`)}
          </Text>
          <Text>개인정보관리책임자: {t(`company.ceoName`)}</Text>
        </VStack>
        <VStack
          align={{ base: `center`, md: `flex-end` }}
          color="gray.500"
          fontSize={{ base: `0.9rem`, md: `1.1rem` }}
        >
          <Text lineHeight="1.2" mb={{ base: 6, md: `0.2rem` }}>
            Copyrights © 2021 {t(`company.brandEN`)}. All rights reserved.
          </Text>
          <Stack align="center" direction="row" flex="1 1 0" justify="center" spacing={3}>
            {footerLinks.map((link) => (
              <FooterLink key={link.href} {...link} />
            ))}
          </Stack>
        </VStack>
      </Flex>
    </Container>
  </Box>
);

export default Footer;

import { Stack, useColorModeValue } from '@chakra-ui/react';

import { navItems } from '_/configs';

import { FooterLink, footerLinks } from '@/layout/footer';

import MobileNavItem from './mobile-nav-item';

const MobileNav = () => (
  <Stack
    bg={useColorModeValue(`white`, `gray.800`)}
    p="1.5rem"
    pb="2rem"
    // pb="100vh"
    display={{ md: `none` }}
  >
    {navItems.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}

    <Stack
      align="center"
      direction="row"
      flex={{ base: 1, md: 0 }}
      justify="flex-end"
      mt="2rem !important"
      opacity="0.8"
      // position="absolute"
      // right="3rem"
      spacing={3}
    >
      {footerLinks
        // .filter((link) => link.label !== `Phone` && link.label !== `Email`)
        .map((link) => (
          <FooterLink key={link.href} {...link} />
        ))}
    </Stack>
  </Stack>
);

export default MobileNav;

import { Dispatch, SetStateAction, useDebugValue, useState } from 'react';

export default function useStateL<S>(
  initialValue: S | (() => S),
  label: string,
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState<S>(initialValue);
  useDebugValue(`${label}: ${value}`);
  return [value, setValue];
}

import cn from 'classnames';
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Container from 'react-bootstrap/Container';
import { Waypoint } from 'react-waypoint';

import { NODE_ENV, NodeEnv } from '_/configs';

import { FloatingItem } from '@/components/animation';
import { AppContext } from '@/contexts';
import { createLog, useGridProps, useStateL } from '@/utils';

import styles from './ContentSection.module.scss';

const log = createLog(`ContentSection`);

export interface ContentSectionProps {
  children: ReactNode;

  // OPTIONAL
  handleEnter?: (args: Waypoint.CallbackArgs) => void;
  handleLeave?: (args: Waypoint.CallbackArgs) => void;
  subtitle?: JSX.Element | string;
  title?: JSX.Element | string;
  waypointBottomOffset?: string | number;
  waypointTopOffset?: string | number;

  // sectionProps
  id: string;

  // OPTIONAL
  className?: string;
  bg?: string;
  bgColor?: `dark` | `gray` | `primary` | `secondary`;
  bgFixed?: boolean;
  bgImg?: string;
  bgDarken?: number;
  bgPos?: string;
  color?: `light`;
  contentTitle?: ReactNode;
  isActive?: boolean;
  isCentered?: boolean;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  isOverflowVisible?: boolean;
  sectionPaddingBottom?: number | string;
  sectionPaddingTop?: number | string;
  sectionStyle?: CSSProperties;
  titleSeparator?: `default` | `dark` | `none`;
  titleStyle?: CSSProperties;
}

const ContentSection = ({
  children,

  // OPTIONAL
  handleEnter,
  handleLeave,
  subtitle,
  title,
  // waypointBottomOffset = -19.5,
  // waypointTopOffset = 10,
  waypointBottomOffset = `-30%`,
  waypointTopOffset = `5%`,
  // waypointBottomOffset = -200,
  // waypointTopOffset = -100,

  // sectionProps
  id,

  // OPTIONAL
  className,
  bg,
  bgColor,
  bgFixed = false,
  bgImg,
  bgDarken = 0,
  bgPos,
  color,
  contentTitle,
  isActive = false,
  isCentered = false,
  isFullHeight = false,
  isFullWidth = false,
  isOverflowVisible = false,
  sectionPaddingBottom,
  sectionPaddingTop,
  sectionStyle,
  titleSeparator = `default`,
  titleStyle,
}: ContentSectionProps) => {
  const { brandEN } = useContext(AppContext);
  const { isMobile } = useGridProps();

  const localSectionStyle = useMemo<CSSProperties>(() => {
    const style: CSSProperties = {};

    style.background = ``;
    if (bg) {
      style.background = bg;
    } else if (bgDarken > 0) {
      style.background = `linear-gradient(
          to bottom,
          rgba(23, 24, 32, ${bgDarken}),
          rgba(23, 24, 32, ${bgDarken})
        ), `;
    }
    if (bgImg) {
      style.background += `url('${bgImg}') no-repeat`;
      style.backgroundSize = `cover`;
    }
    if (bgFixed) style.backgroundAttachment = `fixed`;
    if (bgPos) style.backgroundPosition = bgPos;

    return style;
  }, [bg, bgFixed, bgImg, bgDarken, bgPos]);

  const [isActiveLocal, setActiveLocal] = useStateL(isActive, `isActiveLocal`);
  useEffect(() => {
    setActiveLocal(isActive);
  }, [isActive, setActiveLocal]);
  const localHandleEnter = useCallback(
    (args: Waypoint.CallbackArgs) => {
      if (NODE_ENV !== NodeEnv.PROD) log.debug(`=> ENTER '${id}'`);
      if (isMobile) setActiveLocal(true);
      if (handleEnter) handleEnter(args);
    },
    [handleEnter, id, isMobile, setActiveLocal],
  );
  const localHandleLeave = useCallback(
    (args: Waypoint.CallbackArgs) => {
      if (NODE_ENV !== NodeEnv.PROD) log.debug(`   LEAVE '${id}' =>`);
      if (isMobile) setActiveLocal(false);
      if (handleLeave) handleLeave(args);
    },
    [handleLeave, id, isMobile, setActiveLocal],
  );

  return (
    <section
      id={id}
      className={cn(className, styles.contentSection, {
        [styles.bgDark]: bgColor === `dark`,
        [styles.bgGray]: bgColor === `gray`,
        [styles.bgPrimary]: bgColor === `primary`,
        [styles.bgSecondary]: bgColor === `secondary`,
        [styles.colorLight]: color === `light`,
        [styles.isCentered]: isCentered,
        [styles.isFullHeight]: isFullHeight && !isMobile,
        [styles.isFullWidth]: isFullWidth,
      })}
      style={{
        ...localSectionStyle,
        paddingBottom: sectionPaddingBottom,
        paddingTop: sectionPaddingTop,
        ...sectionStyle,
      }}
    >
      <Waypoint
        bottomOffset={waypointBottomOffset}
        onEnter={localHandleEnter}
        onLeave={localHandleLeave}
        topOffset={waypointTopOffset}
      >
        <div className={styles.contentWrap}>
          {title ? (
            <div
              className={cn(styles.titleWrap, {
                [styles.titleSeparatorDefault]: titleSeparator === `default`,
                [styles.titleSeparatorDark]: titleSeparator === `dark`,
                [styles.titleSeparatorNone]: titleSeparator === `none`,
              })}
              style={{ ...titleStyle }}
            >
              <FloatingItem
                id={`${id}Title`}
                className={styles.title}
                delay={320}
                toggle={isActiveLocal}
              >
                {typeof title === `string` ? (
                  <h2>
                    <em>{title}</em>
                    <br className="md" /> OF {brandEN}
                  </h2>
                ) : (
                  <h2>{title}</h2>
                )}
              </FloatingItem>
              <FloatingItem
                id={`${id}Subtitle`}
                className={styles.subtitle}
                delay={360}
                toggle={isActiveLocal}
              >
                <h3>{subtitle}</h3>
              </FloatingItem>
            </div>
          ) : null}
          <div
            className={cn(styles.content, {
              [styles.isFullWidth]: isFullWidth,
              [styles.overflowVisible]: isOverflowVisible,
            })}
          >
            {contentTitle ? (
              <Container className={styles.contentTitle}>{contentTitle}</Container>
            ) : null}
            <Container className={styles.contentBody}>{children}</Container>
          </div>
        </div>
      </Waypoint>
    </section>
  );
};

export default ContentSection;

import { Box, Button, Text, chakra } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { useBoolean } from 'react-use';

import { __DEV__ } from '_/configs';
import { motionFadeUpProps } from '_/configs/motion-presets';

import { TextUnderlineDivider, TextUnderlineDividerProps } from '@/components';
import { MotionHeading, MotionHeadingProps } from '@/components/chakra-ui/framer-motion';
import { __, useGridProps, useLog, useStateL } from '@/utils';

import type { BoxProps } from '@chakra-ui/react';
import type { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import type { TextUnderlineProps } from '@/components';

const UNIT_NAME = `GeneralSection`;

export enum SectionType {
  DARK = `dark`,
  GRAY = `gray`,
  LIGHT = `light`,
  WHITE = `white`,
}

export enum SectionSize {
  FIT = `fit`,
  XS = `xs`,
  SM = `sm`,
  MD = `md`,
  LG = `lg`,
  XL = `xl`,
  XXL = `xxl`,
}

type BaseSectionProps = PropsWithChildren<{
  name: string;

  // OPTIONAL
  id?: string;
  className?: string;
  bgDarken?: number;
  bgFixed?: boolean;
  bgImg?: CSSProperties['backgroundImage'];
  bgPos?: CSSProperties['backgroundPosition'];
  bgProps?: CSSProperties['background'];
  bgRepeat?: CSSProperties['backgroundRepeat'];
  bgSize?: CSSProperties['backgroundSize'];
  isCentered?: boolean;
  pbMult?: number;
  px?: BoxProps['px'];
  sectionProps?: BoxProps;
  sectionSize?: SectionSize;
  sectionType?: SectionType;
}>;

export type GeneralSectionProps = BaseSectionProps & {
  // OPTIONAL
  containerProps?: BoxProps;
  description?: string | string[] | ReactNode;
  descriptionProps?: BoxProps;
  disableAnimation?: boolean;
  disableAnimationContents?: boolean;
  disableAnimationTitle?: boolean;
  handleInView?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  inViewOptions?: IntersectionOptions;
  title?: string | ReactNode;
  titleProps?: MotionHeadingProps;
  titleTextUnderlineDividerProps?: TextUnderlineDividerProps;
  titleWrapProps?: BoxProps;
};

const GeneralSection = ({
  //
  // BaseSectionProps
  //

  children,
  name,

  // OPTIONAL
  id,
  className,
  bgProps,
  bgFixed = false,
  bgImg,
  bgDarken = 0,
  bgPos = `center center`,
  bgRepeat = `no-repeat`,
  bgSize = `cover`,
  isCentered = false,
  pbMult = 1.3,
  px = { base: `0.75rem`, md: `2rem` },
  sectionProps,
  sectionSize = SectionSize.MD,
  sectionType = SectionType.WHITE,

  //
  // GeneralSectionProps
  //

  // OPTIONAL
  containerProps,
  disableAnimation = false,
  disableAnimationContents = false,
  disableAnimationTitle = false,
  description,
  descriptionProps,
  handleInView,
  inViewOptions,
  title,
  titleProps,
  titleTextUnderlineDividerProps,
  titleWrapProps,
}: GeneralSectionProps) => {
  const { isMobile } = useGridProps();
  const pascalName = useMemo(() => __.pascalCase(name), [name]);
  const log = useLog(`${UNIT_NAME}/${name}`);

  const [inViewRef, inView, inViewEntry] = useInView(inViewOptions);
  useEffect(() => {
    if (handleInView) {
      // TODO: log not printing
      log.debug(`inView: ${inView}, inViewEntry: ${JSON.stringify(inViewEntry)}`);
      handleInView(inView, inViewEntry);
    }
  }, [handleInView, inView, inViewEntry, log, name]);

  const [bg, setBg] = useStateL(undefined, `bg`);
  const [titleColor, setTitleColor] = useStateL(undefined, `titleColor`);
  const [underlineColor, setUnderlineColor] = useStateL(undefined, `underlineColor`);

  useEffect(() => {
    setUnderlineColor(`brand.300`);
    switch (sectionType) {
      case SectionType.DARK:
        setBg(`gray.800`);
        setTitleColor(`white`);
        break;
      case SectionType.GRAY:
        setBg(`bg.gray`);
        setTitleColor(`gray.700`);
        break;
      case SectionType.LIGHT:
        setBg(`gray.50`);
        setTitleColor(`gray.700`);
        break;
      case SectionType.WHITE:
        setBg(`white`);
        setTitleColor(`gray.700`);
        break;
      default:
        throw new Error(`Unknown section type: ${sectionType}`);
    }
  }, [sectionType, setBg, setTitleColor, setUnderlineColor]);

  const { pt, pb } = useMemo(() => {
    let ptLocal: { base: number; md: number };
    // prettier-ignore
    switch (sectionSize) {
      case SectionSize.FIT: ptLocal = { base: 0, md: 0 }; break;
      case SectionSize.XS: ptLocal = { base: 4, md: 4 }; break;
      case SectionSize.SM: ptLocal = { base: 8, md: 8 }; break;
      case SectionSize.MD: ptLocal = { base: 14, md: 14 }; break;
      case SectionSize.LG: ptLocal = { base: 16, md: 16 }; break;
      case SectionSize.XL: ptLocal = { base: 20, md: 20 }; break;
      case SectionSize.XXL: ptLocal = { base: 24, md: 24 }; break;
      default: throw new Error(`Unknown section size: ${sectionSize}`);
    }
    return {
      pt: ptLocal,
      pb: { base: `${ptLocal.base * 4 * pbMult}px`, md: `${ptLocal.md * 4 * pbMult}px` },
    };
  }, [pbMult, sectionSize]);

  const sectionStyleLocal = useMemo<React.CSSProperties>(() => {
    const style: CSSProperties = {};
    style.background = ``;

    if (bgImg) {
      if (bgDarken > 0) {
        style.background = `linear-gradient(
          to bottom,
          rgba(23, 24, 32, ${bgDarken}),
          rgba(23, 24, 32, ${bgDarken})
        ), `;
      }
      style.background += `url('${bgImg}')`;
    } else if (bgProps) {
      style.background = bgProps;
    }
    style.backgroundSize = bgSize;
    style.backgroundPosition = bgPos;
    style.backgroundRepeat = bgRepeat;
    if (bgFixed) style.backgroundAttachment = `fixed`;

    if (style.background === ``) delete style.background;

    return style;
  }, [bgImg, bgProps, bgSize, bgPos, bgRepeat, bgFixed, bgDarken]);

  const [isTitleOpen, setTitleOpen] = useBoolean(false);
  const key = useMemo(() => id || `section${pascalName}`, [id, pascalName]);

  return (
    <Box
      ref={handleInView ? inViewRef : undefined}
      key={key}
      id={key}
      className={className}
      style={{ ...sectionStyleLocal }}
      as="section"
      bg={bg}
      overflow="hidden"
      pb={pb}
      pt={pt}
      px={px}
      position="relative"
      textAlign={isCentered ? `center` : undefined}
      {...sectionProps}
    >
      <AnimatePresence
        key={`${key}-title-AnimatePresence`}
        initial={!(disableAnimation || disableAnimationTitle)}
      >
        {__DEV__ && (
          <Button
            key={`${key}-titleOpenButton`}
            variant="unstyled"
            position="absolute"
            top={0}
            left={0}
            onClick={() => setTitleOpen(!isTitleOpen)}
          >
            &nbsp;
          </Button>
        )}
        <Box
          key={`${key}-contentsWrap`}
          color={titleColor}
          maxW={sectionSize !== SectionSize.FIT && `7xl`}
          pb={pb}
          pt={pt}
          px={px}
          mx="auto"
          {...containerProps}
        >
          {title ? (
            <Box
              key={`${key}-titleWrap`}
              maxW="992px"
              mx="auto"
              textAlign="center"
              mb={{
                base: description ? `3rem` : `1.5rem`,
                md: description ? `5rem` : `2.5rem`,
              }}
              {...titleWrapProps}
            >
              {title && (
                <MotionHeading
                  key={`${key}-title`}
                  as="h2"
                  textStyle="heading"
                  {...motionFadeUpProps}
                  {...titleProps}
                >
                  <TextUnderlineDivider
                    colorLight={titleColor}
                    underlineColorLight={underlineColor}
                    {...titleTextUnderlineDividerProps}
                  >
                    {title}
                  </TextUnderlineDivider>
                </MotionHeading>
              )}
              {description && (
                <MotionHeading
                  key={`${key}-description`}
                  as="h2"
                  textStyle="heading"
                  {...motionFadeUpProps}
                  {...titleProps}
                >
                  <chakra.p textStyle="description" {...descriptionProps}>
                    {Array.isArray(description) && !isMobile
                      ? description.map((item, index) => <Text key={index}>{item}</Text>)
                      : description}
                  </chakra.p>
                </MotionHeading>
              )}
            </Box>
          ) : null}
          <AnimatePresence
            key={`${key}-contents-AnimatePresence`}
            initial={!(disableAnimation || disableAnimationContents)}
          >
            {children}
          </AnimatePresence>
        </Box>
      </AnimatePresence>
    </Box>
  );
};

export default GeneralSection;

import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Collapse,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import { useMemo } from 'react';

import { createLog } from '@/utils';

import type { NavItem } from '_/configs';

const log = createLog(`MobileNavItem`);

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const disableHref = href.includes(`/business/`);
  const { pathname } = useRouter();
  const { isOpen, onToggle } = useDisclosure();

  const isActive = useMemo(() => {
    const isActiveLocal =
      (href?.includes(`/business/`) && pathname?.includes(`/business/`)) ||
      pathname === href;
    log.debug(`isActive: ${isActiveLocal}, pathname: ${pathname}, href: ${href}`);
    return isActiveLocal;
  }, [href, pathname]);

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        align="center"
        as={Link}
        color={isActive && `brand.500`}
        href={!disableHref ? href : undefined}
        justify="space-between"
        py={2}
        _hover={{ textDecoration: `none` }}
      >
        <Text>{label}</Text>
        {children && (
          <Icon
            as={ChevronLeftIcon}
            h={6}
            transform={isOpen ? `rotate(-90deg)` : ``}
            transitionDuration="normal"
            transitionProperty="all"
            transitionTimingFunction="ease-in-out"
            w={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: `0 !important` }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue(`gray.200`, `gray.700`)}
          align="start"
          textStyle="navbar-sub"
        >
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                href={child.href}
                py={2}
                w="100%"
                _hover={{ textDecoration: `none` }}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNavItem;

import { fadeInProps, fadeUpProps, visibleInViewProps } from '_/configs/motion-values';

export const motionFadeUpSlowProps = {
  ...visibleInViewProps,
  ...fadeInProps.slow,
  ...fadeUpProps,
};

export const motionFadeUpProps = {
  ...visibleInViewProps,
  ...fadeInProps.normal,
  ...fadeUpProps,
};

import React from 'react';
import { SpringValue, animated } from '@react-spring/web';

export interface SvgProps {
  // REQUIRED
  id: string;

  // OPTIONAL
  className?: string;
  enableBackground?: string;
  height?: string;
  viewBox?: string;
  width?: string;
  xmlns?: string;
}

export interface Props {
  // REQUIRED
  children: React.ReactNode;

  // OPTIONAL
  style?: React.CSSProperties;

  // svgProps
  // OPTIONAL
  svgProps?: SvgProps;
  varOpacity?: SpringValue<number>;
}

const AnimatedSvg = ({
  // REQUIRED
  children,

  // OPTIONAL
  style = {},

  // svgProps
  // OPTIONAL
  svgProps,
  varOpacity,
}: Props) => {
  return (
    <animated.svg
      {...svgProps}
      style={{
        ...style,
        opacity: varOpacity
          ? varOpacity.to({
              range: [0.0, 1.0],
              output: [0, 1],
            })
          : 1,
      }}
    >
      {children}
    </animated.svg>
  );
};

export default AnimatedSvg;

import type { Dispatch, Reducer } from 'react';

import { useDebugValue, useReducer } from 'react';

export type { Dispatch, Reducer };

export default function useReducerL<S, I, A>(
  label: string,
  reducer: Reducer<S, A>,
  initialArgs: I,
  init: (i: I) => S,
): [S, Dispatch<A>] {
  const [state, dispatch] = useReducer(reducer, initialArgs, init);
  useDebugValue(`${label}: ${JSON.stringify(state)}`);
  return [state, dispatch];
}

// import _ from 'lodash';

import __str from './__str';

export const buildCss = (object: Record<string, unknown>, keys?: string[]): string =>
  __str.objectJoinToString(object, ` `, keys);

export const generateBuildBgImg =
  ({ path, fileExt = ``, bgAlpha = 0, bgAlphaColor = `23, 24, 32` }) =>
  (filename: string) =>
    `linear-gradient(rgba(${bgAlphaColor}, ${bgAlpha}), rgba(${bgAlphaColor}, ${bgAlpha})), url('${path}/${filename}${fileExt}')`;

const __css = {
  buildCss,
  generateBuildBgImg,
};

export default __css;

import { UseTransitionProps, config, useTransition } from '@react-spring/web';
import React, { useEffect } from 'react';

import { createLog, useStateL } from '@/utils';

const log = createLog(`SvgHoverTransition`);

export interface SvgHoverTransitionProps {
  // REQUIRED
  Svg1: any;
  Svg2: any;
  toggle: boolean;

  // OPTIONAL
  autoToggle?: boolean;
  autoToggleDelay?: number;
  autoToggleDuration?: number;
  springProps?: UseTransitionProps;
  style?: React.CSSProperties;
}

const SvgHoverTransition = ({
  // REQUIRED
  Svg1,
  Svg2,
  toggle,

  // OPTIONAL
  autoToggle = false,
  autoToggleDelay = 200,
  autoToggleDuration = 1000,
  springProps = {
    from: { position: `absolute`, opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 100,
    config: config.molasses,
  },
  style = {},
}: SvgHoverTransitionProps) => {
  const [localToggle, setLocalToggle] = useStateL(toggle, `localToggle`);
  useEffect(() => {
    setLocalToggle(toggle);
  }, [setLocalToggle, toggle]);

  useEffect(() => {
    if (autoToggle) {
      log.debug(`autoToggle`);
      const timeout1 = setTimeout(() => {
        log.debug(`timeout1`);
        setLocalToggle(true);
      }, autoToggleDelay);

      const timeout2 = setTimeout(() => {
        log.debug(`timeout2`);
        setLocalToggle(false);
      }, autoToggleDelay + autoToggleDuration);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      };
    }
    return () => null;
  }, [autoToggle, autoToggleDelay, autoToggleDuration, setLocalToggle]);

  const transitions = useTransition(localToggle, springProps);

  return (
    <>
      {transitions(({ opacity }: any, toggleItem: boolean) => {
        const svgProps = { opacity, style };
        return toggleItem ? <Svg2 {...svgProps} /> : <Svg1 {...svgProps} />;
      })}
    </>
  );
};

export default SvgHoverTransition;

export interface KakaoMapConfig {
  centerLatLng: [number, number];
  markerPositions: [number, number][];

  // OPTIONAL
  zoomLevel?: number;
  zoomable?: boolean;
}

export interface ReactSpringConfig {
  mass: number;
  tension: number;
  friction: number;
}

// TODO: group all default values here for readability
const DEFAULT_ZOOM_LEVEL = parseInt(process.env.NEXT_PUBLIC_DEFAULT_ZOOM_LEVEL) || 3;
const DEFAULT_ZOOM_LEVEL_PC =
  parseInt(process.env.NEXT_PUBLIC_DEFAULT_ZOOM_LEVEL_PC) || 3;
const DEFAULT_ZOOM_LEVEL_MOBILE =
  parseInt(process.env.NEXT_PUBLIC_DEFAULT_ZOOM_LEVEL_MOBILE) || 5;

export default class Configs {
  static getEnvVars() {
    return {
      basePath: process.env.BASE_PATH,
      brandEN: process.env.NEXT_PUBLIC_BRAND_EN || `INNO&M`,
      brandKO: process.env.NEXT_PUBLIC_BRAND_KO || `이노앤엠`,
      disableMap: process.env.NEXT_PUBLIC_DISABLE_MAP === `true`,
      kakaoMapApiKey: process.env.NEXT_PUBLIC_KAKAO_MAP_API_KEY || ``,
    };
  }

  static getEnvVar(key: string, defaultValue?: string) {
    return process.env[key] || defaultValue;
  }

  static isCiMode() {
    return process.env.CI === `true`;
  }

  static isVerboseMode() {
    return process.env.VERBOSE === `true`;
  }

  static getReactSpringConfigs(): Record<string, ReactSpringConfig> {
    return {
      stiff2: { mass: 1, tension: 280, friction: 30 },
    };
  }

  static getKakaoMapConfigs(
    // OPTIONAL
    log: any = undefined,
    isMobile: boolean = undefined,
    zoomLevel = DEFAULT_ZOOM_LEVEL,
    zoomable = false,
  ): KakaoMapConfig {
    log?.debug(`isMobile: ${isMobile}, zoomLevel: ${zoomLevel}, zoomable: ${zoomable}`);

    const markerPositions: [number, number][] = [
      [
        parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_MARKER_LAT) || 37.501752191269595,
        parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_MARKER_LNG) || 127.03406025057782,
      ],
    ];

    const centerOffset = isMobile
      ? [
          parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_CENTER_OFFSET_X_MOBILE) || +0.0025,
          parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_CENTER_OFFSET_Y_MOBILE) || +0.002,
        ]
      : [
          parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_CENTER_OFFSET_X_PC) || -0.0001,
          parseFloat(process.env.NEXT_PUBLIC_KAKAO_MAP_CENTER_OFFSET_Y_PC) || -0.0055,
        ];

    const centerLatLng: [number, number] = [
      markerPositions[0][0] + centerOffset[0],
      markerPositions[0][1] + centerOffset[1],
    ];

    log?.debug(`markerPositions: ${JSON.stringify(markerPositions)}`);
    log?.debug(`centerOffset: ${JSON.stringify(centerOffset)}`);
    log?.debug(`centerLatLng: ${JSON.stringify(centerLatLng)}`);

    return {
      centerLatLng,
      markerPositions,

      // OPTIONAL
      zoomLevel,
      zoomable,
    };
  }

  static getKakaoMapZoomLevel(critBreakpoint?: number, curBreakpoint?: number) {
    if (!curBreakpoint || !critBreakpoint) return DEFAULT_ZOOM_LEVEL;
    if (curBreakpoint > critBreakpoint) return DEFAULT_ZOOM_LEVEL_PC;
    return DEFAULT_ZOOM_LEVEL_MOBILE;
  }
}

import React from 'react';
import { animated, useSpring } from '@react-spring/web';

export interface FloatingProps {
  // REQUIRED
  children: React.ReactNode;
  open: boolean;

  // OPTIONAL
  // onClick?: () => void;
  delay?: number;
  duration?: number;
}

const Floating = ({
  // REQUIRED
  children,
  open,

  // OPTIONAL
  // onClick,
  delay = 0,
  duration = 300,
}: FloatingProps) => {
  const spring = useSpring({
    config: { mass: 5, tension: 2000, friction: 200, duration },
    from: { opacity: 0, transform: `translateY(50px)` },
    to: {
      opacity: open ? 1 : 0,
      transform: open ? `translateY(0)` : `translateY(50px)`,
    },
    delay,
  });

  return <animated.div style={spring}>{children}</animated.div>;
};

export default Floating;

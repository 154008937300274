import React from 'react';
import { Waypoint } from 'react-waypoint';

import { NODE_ENV, NodeEnv } from '_/configs';

import { createLog, useStateL } from '@/utils';

const log = createLog(`FloatingTrails`);

export interface FloatingTrailsProps {
  id: string;
  children: React.ReactNode;
}

const FloatingTrails = ({ id, children }: FloatingTrailsProps) => {
  const [, setToggle] = useStateL({ toggle: false }, `toggle`);

  const onEnter = () => {
    if (NODE_ENV !== NodeEnv.PROD) {
      log.debug(`enter trails '${id}'`);
    }
    setToggle({ toggle: true });
  };

  const onLeave = () => {
    if (NODE_ENV !== NodeEnv.PROD) {
      log.debug(`leave trails '${id}'`);
    }
    setToggle({ toggle: false });
  };

  return (
    <Waypoint key={id} onEnter={onEnter} onLeave={onLeave}>
      {children}
    </Waypoint>
  );
};

export default FloatingTrails;

import { HTMLChakraProps, chakra, forwardRef } from '@chakra-ui/react';
import { MotionProps, isValidMotionProp, motion } from 'framer-motion';
import React from 'react';

export type MotionMainProps = HTMLChakraProps<`main`> | MotionProps;

const MotionMainRef = forwardRef((props, ref) => {
  const chakraProps = Object.fromEntries(
    // do not pass framer props to DOM element
    Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
  );
  return <chakra.main ref={ref} {...chakraProps} />;
});

const MotionMain = motion(MotionMainRef);

MotionMain.displayName = `MotionMain`;

export default MotionMain;

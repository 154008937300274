import React from 'react';
import { animated, useSpring } from '@react-spring/web';

export interface PathProps {
  // REQUIRED
  id: string;

  // OPTIONAL
  d?: string;
  style?: React.CSSProperties;
}

export interface AnimatedPathProps {
  // OPTIONAL
  pathProps?: PathProps;

  // react-spring
  // OPTIONAL
  delay?: number;
  toggleFill?: boolean;
  varFill1?: string;
  varFill2?: string;
}

const AnimatedPath = ({
  // OPTIONAL
  pathProps,

  // react-spring
  // OPTIONAL
  delay = 250,
  toggleFill = false,
  varFill1,
  varFill2,
}: AnimatedPathProps) => {
  const animationFillStyle = useSpring({
    fill: toggleFill ? varFill2 : varFill1,
    delay,
  });

  return <animated.path {...pathProps} style={animationFillStyle} />;
};

export default AnimatedPath;

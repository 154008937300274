import { createMedia } from '@artsy/fresnel';

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const Boostrap4Media = createMedia({ breakpoints });

// Make styles for injection into the header of the page
export const mediaStyle = Boostrap4Media.createMediaStyle();
export const { Media, MediaContextProvider } = Boostrap4Media;

// REF: https://stackoverflow.com/questions/29209244/css-floating-action-button

import { Link } from '@chakra-ui/react';
import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import { BsChatFill } from 'react-icons/bs';
import { RiEditBoxFill, RiMailFill, RiPhoneFill } from 'react-icons/ri';

import { t } from '@/utils';

import styles from './SnsFloatingButtons.module.scss';

export interface SnsFloatingButtonsProps {
  // OPTIONAL
  show?: boolean;
}

const SnsFloatingButtons = ({
  // OPTIONAL
  show = true,
}: SnsFloatingButtonsProps) => {
  const wrapSpring = useSpring({ opacity: show ? 1 : 0 });

  return (
    <animated.div className={styles.buttonWrap} style={wrapSpring}>
      <button type="button" className={cn(styles.button, styles.main)}>
        <BsChatFill className={styles.icon} />
      </button>
      <div className={styles.subButtonWrap}>
        <Link
          type="button"
          href="/contact"
          _hover={{ color: `brand.300` }}
          className={cn(styles.button, styles.sub)}
        >
          <RiEditBoxFill className={styles.icon} />
        </Link>
        <Link
          type="button"
          href={`tel:${t(`company.phoneNum`)}`}
          _hover={{ color: `brand.300` }}
          className={cn(styles.button, styles.sub)}
        >
          <RiPhoneFill className={styles.icon} />
        </Link>
        <Link
          type="button"
          href={`mailto:${t(`company.contactEmail`)}`}
          _hover={{ color: `brand.300` }}
          className={cn(styles.button, styles.sub)}
        >
          <RiMailFill className={styles.icon} />
        </Link>
      </div>
    </animated.div>
  );
};

export default SnsFloatingButtons;

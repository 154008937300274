import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { MotionProps, isValidMotionProp, motion } from 'framer-motion';
import React from 'react';

export type MotionBoxProps = BoxProps | MotionProps;

const MotionBoxRef = forwardRef((props, ref) => {
  const chakraProps = Object.fromEntries(
    // do not pass framer props to DOM element
    Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
  );
  return <Box ref={ref} {...chakraProps} />;
});

const MotionBox = motion(MotionBoxRef);

MotionBox.displayName = `MotionBox`;

export default MotionBox;

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react';

import type { NavItem } from '_/configs';

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => (
  <Link
    href={href}
    role="group"
    display="block"
    p={2}
    rounded="md"
    _hover={{ textDecoration: `none`, color: `brand.500` }}
  >
    <Stack direction="row" align="center">
      <Box>
        <Text
          _groupHover={{ color: `brand.500` }}
          fontSize="18px"
          fontWeight={500}
          transitionProperty="all"
          transitionDuration="faster"
          transitionTimingFunction="ease-in-out"
        >
          {label}
        </Text>
        <Text fontSize="sm">{subLabel}</Text>
      </Box>
      <Flex
        transitionProperty="all"
        transitionDuration="faster"
        transitionTimingFunction="ease-in-out"
        opacity={0}
        _groupHover={{ opacity: `100%` }}
        justify="flex-end"
        align="center"
        flex={1}
      >
        <Icon color="brand.400" w={5} h={5} as={ChevronRightIcon} />
      </Flex>
    </Stack>
  </Link>
);

export default DesktopSubNav;

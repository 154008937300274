import React, { useMemo } from 'react';

import { ChakraProps, chakra } from '@chakra-ui/react';

export interface BrandLogoStyle {
  fill1: string;
  fill2: string;
  fill3: string;

  // OPTIONAL
  fillOpacity?: number;
}

export const brandLogoStyles: Record<string, BrandLogoStyle> = {
  default: {
    fill1: `#00a1e9`,
    fill2: `#0b3190`,
    fill3: `#181c62`,

    // OPTIONAL
    fillOpacity: 1,
  },
  light: {
    fill1: `white`,
    fill2: `white`,
    fill3: `white`,

    // OPTIONAL
    fillOpacity: 1,
  },
} as const;

export interface BrandLogoProps {
  // OPTIONAL
  logoStyleType?: keyof typeof brandLogoStyles;
  svgProps?: ChakraProps;
}

const BrandLogo = ({
  // OPTIONAL
  logoStyleType = `default`,
  svgProps,
}: BrandLogoProps): React.ReactElement => {
  const logoStyle = useMemo<BrandLogoStyle>(
    () => brandLogoStyles[logoStyleType],
    [logoStyleType],
  );

  return (
    <chakra.svg viewBox="0 0 755.90533 133.0032" {...svgProps}>
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,133.0032)">
        <g id="g12" transform="translate(6.0687,99.7524)">
          <path
            id="path14"
            d="m 0,0 -6.069,-4.454 v -6.094 L 0,-6.094 6.068,-10.548 v 6.094 z"
            style={{
              fill: logoStyle.fill1,
              fillOpacity: logoStyle.fillOpacity,
              fillRule: `nonzero`,
              stroke: `none`,
            }}
          />
        </g>
        <g id="g16" transform="translate(0,82.494)">
          <path
            id="path18"
            d="m 0,0 v -6.094 l 6.069,4.454 6.068,-4.454 V 0 L 6.069,4.454 Z"
            style={{
              fill: logoStyle.fill2,
              fillOpacity: logoStyle.fillOpacity,
              fillRule: `nonzero`,
              stroke: `none`,
            }}
          />
        </g>
        <g id="g20">
          <g id="g22" clipPath="url(#clipPath26)">
            <g id="g28" transform="translate(241.2803,44.8486)">
              <path
                id="path30"
                d="M 0,0 C 0,18.039 14.675,32.713 32.71,32.713 50.749,32.713 65.423,18.039 65.423,0 V -2.207 H 77.56 V 0 c 0,24.729 -20.121,44.849 -44.85,44.849 -24.729,0 -44.846,-20.12 -44.846,-44.849 0,-24.729 20.117,-44.849 44.846,-44.849 h 2.207 v 12.137 H 32.71 C 14.675,-32.712 0,-18.038 0,0"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g32" transform="translate(313.1636,89.6977)">
              <path
                id="path34"
                d="M 0,0 H -2.206 V -12.136 H 0 c 18.039,0 32.713,-14.675 32.713,-32.713 0,-18.038 -14.674,-32.713 -32.713,-32.713 -18.036,0 -32.71,14.675 -32.71,32.713 v 2.206 h -12.136 v -2.206 c 0,-24.729 20.117,-44.849 44.846,-44.849 24.729,0 44.849,20.12 44.849,44.849 C 44.849,-20.12 24.729,0 0,0"
                style={{
                  fill: logoStyle.fill1,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g36" transform="translate(6.0684,74.144)">
              <path
                id="path38"
                d="M 0,0 -6.068,-4.454 V -71.225 H 6.068 v 66.771 z"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g40" transform="translate(98.8389,21.6626)">
              <path
                id="path42"
                d="m 0,0 -55.235,65.115 h -12.154 v -83.858 l 12.137,0.005 v 65.113 l 55.23,-65.113 6.092,-0.005 6.066,0.005 V 65.115 H 0 Z"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g44" transform="translate(398.7329,65.5913)">
              <path
                id="path46"
                d="m 0,0 c -0.78,0.819 -1.446,1.748 -1.98,2.758 l -0.002,0.005 c -0.223,0.415 -0.485,1.206 -0.485,2.622 0,1.278 0.279,2.357 0.885,3.403 0.586,1.014 1.343,1.754 2.379,2.329 1.086,0.601 2.245,0.894 3.545,0.894 1.345,0 2.506,-0.286 3.551,-0.872 1.024,-0.578 1.775,-1.324 2.368,-2.349 0.608,-1.052 0.89,-2.134 0.89,-3.405 0,-2.204 -0.698,-3.728 -2.217,-5.006 L 3.331,-3.704 Z m -7.068,-26.877 8.732,6.878 21.966,-24.419 c -2.237,-2.98 -5.081,-5.372 -8.475,-7.126 -3.213,-1.655 -6.542,-1.868 -9.083,-1.868 -3.409,0 -6.611,0.758 -9.52,2.254 -2.877,1.476 -5.071,3.396 -6.708,5.863 -1.541,2.325 -2.291,4.771 -2.291,7.478 0,2.049 0.455,3.98 1.39,5.901 0.946,1.942 2.3,3.646 3.989,5.039 m 49.266,3.288 0.134,2.332 H 30.185 l -0.125,-2.074 c -0.153,-2.505 -0.423,-5.585 -1.125,-8.838 l -17.443,19.393 4.822,3.51 0.427,0.36 c 4.281,3.646 6.546,8.587 6.546,14.291 0,3.392 -0.847,6.581 -2.519,9.479 -1.683,2.905 -4.012,5.211 -6.923,6.85 -5.721,3.213 -13.149,3.215 -18.93,0.017 -2.961,-1.641 -5.317,-3.951 -7.002,-6.869 -1.67,-2.883 -2.517,-6.072 -2.517,-9.477 0,-3.148 0.641,-5.945 1.901,-8.311 1.034,-1.957 2.362,-3.81 3.795,-5.314 l 2.443,-2.718 -8.216,-6.466 c -3.12,-2.569 -5.572,-5.657 -7.288,-9.18 -1.735,-3.562 -2.614,-7.335 -2.614,-11.213 0,-5.103 1.453,-9.876 4.314,-14.186 2.776,-4.185 6.568,-7.531 11.274,-9.951 4.64,-2.385 9.708,-3.594 15.067,-3.594 3.168,0 7.973,0.258 13.099,2.504 l 1.321,0.58 v 0.025 c 0.076,0.035 0.151,0.074 0.227,0.112 4.342,2.239 8.08,5.176 11.145,8.753 l 8.421,-9.361 h 16.32 l -18.219,20.258 c 3.174,7.917 3.618,15.673 3.812,19.088"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g48" transform="translate(197.6772,21.6626)">
              <path
                id="path50"
                d="m 0,0 -55.235,65.115 h -12.154 v -83.858 l 12.137,0.005 v 65.113 l 55.231,-65.113 6.091,-0.005 6.066,0.005 V 65.115 H 0 Z"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
            <g id="g52" transform="translate(550.7549,86.7778)">
              <path
                id="path54"
                d="M 0,0 -35.835,-68.554 -71.67,0 h -11.762 v -83.858 h 12.137 v 56.946 l 29.767,-56.946 h 11.386 l 34.18,65.389 V -83.858 H 16.174 V 0 Z"
                style={{
                  fill: logoStyle.fill3,
                  fillOpacity: logoStyle.fillOpacity,
                  fillRule: `nonzero`,
                  stroke: `none`,
                }}
              />
            </g>
          </g>
        </g>
      </g>
    </chakra.svg>
  );
};

export default BrandLogo;

export enum GridBreakpoints {
  XS = 0,
  SM = 576,
  // SM = 480,
  MD = 768,
  LG = 992,
  XL = 1200,
  // XL = 1280,
  XXL = 1400,
  UNKNOWN = -1,
}

export enum ContainerMaxWidths {
  XS = 0,
  SM = 540,
  MD = 720,
  LG = 960,
  XL = 1140,
  XXL = 1320,
  UNKNOWN = -1,
}

export interface WindowSize {
  height: number;
  width: number;
}

export interface GridProps {
  containerMaxWidth: number;
  gridBreakpoint: number;
  isDesktop: boolean;
  isMobile: boolean;
  // windowSize: WindowSize;
}

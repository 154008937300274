import __css from './__css';
import __obj from './__obj';
import __str from './__str';

const __ = {
  ...__css,
  ...__obj,
  ...__str,
};

export default __;

export { __css, __obj, __str };

export { default as Container } from './container';
export { default as ContentSection } from './content-section/content-section';
export { default as ContentSection2 } from './content-section-2/ContentSection';
export { default as Footer } from './footer';
export { default as GeneralSection, SectionSize, SectionType } from './section';
export { default as Header } from './header';
export { default as ResponsiveBr } from './responsive-br';
export { default as SEO } from './seo';

export type { ContentSectionProps } from './content-section/content-section';
export type { ContentSectionProps as ContactSection2Props } from './content-section-2/ContentSection';
// TODO: export type { FooterProps } from './footer';
export type { HeaderProps } from './header';
export type { GeneralSectionProps } from './section';
export type { SEOProps } from './seo';

export interface NavItem {
  label: string;

  // OPTIONAL
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const navItems: NavItem[] = [
  {
    label: `Home`,
    href: `/`,
  },
  {
    label: `About`,
    href: `/about`,
  },
  {
    label: `Business`,
    href: `/business/viral`,
    children: [
      {
        label: `바이럴 마케팅`,
        href: `/business/viral`,
      },
      // {
      //   label: `SNS 운영대행`,
      //   href: `/business/sns`,
      // },
      {
        label: `언론 송출`,
        href: `/business/media`,
      },
      {
        label: `검색 광고`,
        href: `/business/searchad`,
      },
      {
        label: `종합 마케팅`,
        href: `/business/marketing`,
      },
    ],
  },
  {
    label: `Contact`,
    href: `/contact`,
  },
];

export default navItems;

import { Box, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import type { PropsWithChildren } from 'react';

export interface TextUnderlineProps {
  // OPTIONAL
  colorDark?: string;
  colorLight?: string;
  hide?: boolean;
  // TODO: impl
  // hoverColorDark?: string;
  // hoverColorLight?: string;
  show?: boolean;
  underlineColorDark?: string;
  underlineColorLight?: string;
  underlineHeight?: string;
  underlineLeft?: number | string;
  underlineRight?: number | string;
  underlineWidth?: string;
}

const TextUnderline = ({
  children,

  // OPTIONAL
  colorDark = `whiteAlpha.900`,
  colorLight = `gray.700`,
  hide = false,
  // hoverColorDark,
  // hoverColorLight,
  show = true,
  underlineColorDark = `brand.200`,
  underlineColorLight = `brand.300`,
  underlineHeight = `30%`,
  underlineLeft = 0,
  underlineRight = 0,
  underlineWidth = `full`,
}: PropsWithChildren<TextUnderlineProps>): JSX.Element => {
  return (
    <Box
      as="span"
      color={useColorModeValue(colorLight, colorDark)}
      position="relative"
      zIndex={0}
      _after={{
        bg: useColorModeValue(underlineColorLight, underlineColorDark),
        bottom: underlineRight,
        content: `""`,
        display: hide ? `none` : `block`,
        h: underlineHeight,
        left: underlineLeft,
        opacity: show ? 1 : 0,
        position: `absolute`,
        transitionProperty: `opacity`,
        transitionDuration: `normal`,
        transitionTimingFunction: `ease-in-out`,
        w: underlineWidth,
        zIndex: -1,
      }}
    >
      {children}
    </Box>
  );
};

export default TextUnderline;

import { chakra } from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';
import React from 'react';

export interface NaverLogoIconProps {
  // OPTIONAL
  svgProps?: ChakraProps;
}

const NaverLogoIcon = ({
  // OPTIONAL
  svgProps,
}: NaverLogoIconProps): JSX.Element => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 512 512"
      height="1.15em"
      width="1.15em"
      {...svgProps}
    >
      <title />
      {/* <path d="M126,128H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H126a2,2,0,0,1,2,2V126A2,2,0,0,1,126,128ZM4,124H124V4H4Z" />
      <path d="M98,97H75a2,2,0,0,1-1.63-.84L55,70.27V95a2,2,0,0,1-2,2H30a2,2,0,0,1-2-2V33a2,2,0,0,1,2-2H53a2,2,0,0,1,1.63.84L73,57.73V33a2,2,0,0,1,2-2H98a2,2,0,0,1,2,2V95A2,2,0,0,1,98,97ZM76,93H96V35H77V64a2,2,0,0,1-3.63,1.16L52,35H32V93H51V64a2,2,0,0,1,3.63-1.16Z" /> */}

      <path
        fill="currentColor"
        d="M9 32V480H181.366V255.862L331.358 480H504V32H331.358V255.862L181.366 32H9Z"
      ></path>
    </chakra.svg>
  );
};

export default NaverLogoIcon;

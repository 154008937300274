import _ from 'lodash';

import __str from './__str';

export const getSortedKeys = (obj: Record<string, unknown>): string[] => {
  const keys = Object.keys(obj);
  return _.sortBy(keys);
};

export const isBlankOrEmpty = (value: unknown) =>
  _.isString(value) ? !_.trim(value) : _.isEmpty(value);

export const sortByKeys = (object: Record<string, unknown>) => {
  const sortedKeys = getSortedKeys(object);
  return _.map(sortedKeys, (key: string) => ({ [key]: object[key] }));
};

const __obj = {
  getSortedKeys,
  isBlankOrEmpty,
  sortByKeys,
};

export default __obj;

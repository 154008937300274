import React from 'react';

import { breakpoints } from '_/theme';

import type { HTMLAttributes } from 'react';

export interface ResponsiveBrProps {
  breakpoint: keyof typeof breakpoints;

  // OPTIONAL
  brProps?: HTMLAttributes<HTMLBRElement>;
}

const ResponsiveBr = ({
  breakpoint,

  // OPTIONAL
  brProps,
}: ResponsiveBrProps) => <br className={breakpoint} {...brProps} />;

export default ResponsiveBr;

import _ from 'lodash';

import __obj from './__obj';

export const isBlank = (str: string) => !_.trim(str);

export const objectJoinToString = (
  object: Record<string, unknown>,
  separator?: string,
  keys?: string[],
): string => {
  let picked: Record<string, unknown>;
  if (keys && keys.length > 0) {
    picked = _.pick(object, keys);
  } else {
    picked = object;
  }

  if (!separator) {
    separator = `,`;
  }

  const sortedKeys = __obj.getSortedKeys(picked);
  // console.log(sortedKeys)

  const mapped = _.map(
    sortedKeys,
    (key: string, idx: number) => `${separator}${picked[sortedKeys[idx]]}`,
  ).join(``);
  // console.log(mapped)

  return mapped;
};

export const pascalCase = (str: string): string => _.upperFirst(_.camelCase(str));

export const toBoolean = (str: string): boolean => JSON.parse(str);

export const toBooleanHuman = (str: string): boolean => {
  switch (str.toLowerCase().trim()) {
    case `true`:
    case `yes`:
    case `1`:
      return true;

    case `false`:
    case `no`:
    case `0`:
    case null:
      return false;

    default:
      return Boolean(str);
  }
};

const __str = {
  isBlank,
  objectJoinToString,
  pascalCase,
  toBoolean,
  toBooleanHuman,
};

export default __str;

import React from 'react';

export interface AppContextProps {
  brandEN: string;
  brandKO: string;
  disableMap: boolean;
  kakaoMapApiKey: string;
}

// TODO: add KakaoMapPropsContext
export const getAppContextValue = (): AppContextProps => ({
  brandEN: process.env.NEXT_PUBLIC_BRAND_EN || `INNO&M`,
  brandKO: process.env.NEXT_PUBLIC_BRAND_KO || `이노앤엠`,
  disableMap: process.env.NEXT_PUBLIC_DISABLE_MAP === `true`,
  kakaoMapApiKey: process.env.NEXT_PUBLIC_KAKAO_MAP_API_KEY || ``,
});

export const AppContext = React.createContext<AppContextProps>(getAppContextValue());

import React, { ReactNode, useEffect } from 'react';

import { Floating } from '@/components/react-spring';
import { useStateL } from '@/utils';

// const log = createLog(`FloatingItem`);

export interface FloatingItemProps {
  // REQUIRED
  children: React.ReactNode;
  toggle: boolean;

  // OPTIONAL
  // handleEnter?: (idx: number | undefined, args: Waypoint.CallbackArgs) => void;
  // handleLeave?: (idx: number | undefined, args: Waypoint.CallbackArgs) => void;
  // idx?: number;
  // offsetBottom?: string | number;
  // offsetTop?: string | number;
  // onClick?: () => void;
  id?: string;
  className?: string;
  delay?: number;
  disabled?: boolean;
  duration?: number;
}

const FloatingItem = ({
  // REQUIRED
  children,
  toggle,

  // OPTIONAL
  // handleEnter,
  // handleLeave,
  // idx,
  // offsetBottom = `0px`,
  // offsetTop = `0px`,
  // onClick,
  id,
  className,
  delay = 300,
  disabled = false,
  duration = 300,
}: FloatingItemProps) => {
  // const [toggle, setToggle] = useStateL(false, `toggle`);

  // useEffect(() => {
  //   if (isActive) setToggle(isActive);
  // }, [isActive, setToggle]);

  // const localHandleEnter = (args: Waypoint.CallbackArgs) => {
  //   log.debug(`<= ENTER FloatingItem '${id}'`);
  //   setToggle(true);

  //   if (handleEnter) handleEnter(idx, args);
  // };

  // const localHandleLeave = (args: Waypoint.CallbackArgs) => {
  //   log.debug(`=> LEAVE FloatingItem '${id}'`);
  //   setToggle(false);

  //   if (handleLeave) handleLeave(idx, args);
  // };

  const [toRender, setToRender] = useStateL<ReactNode>(undefined, `toRender`);

  // TODO: restore waypoint feature
  useEffect(() => {
    setToRender(
      // <Waypoint
      //   key={id}
      //   onEnter={localHandleEnter}
      //   onLeave={localHandleLeave}
      //   topOffset={offsetTop}
      //   bottomOffset={offsetBottom}
      // >
      <div id={id} className={className}>
        {disabled ? (
          children
        ) : (
          <Floating open={toggle} delay={delay} duration={duration}>
            {children}
          </Floating>
        )}
      </div>,
      // </Waypoint>
    );
  }, [children, className, delay, disabled, duration, id, setToRender, toggle]);

  return <>{toRender}</>;
};

export default FloatingItem;

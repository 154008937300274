import {
  Box,
  HStack,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';

import { navItems } from '_/configs';

import { createLog } from '@/utils';

import DesktopSubNav from './desktop-sub-nav';

const UNIT_NAME = `DesktopNav`;
const log = createLog(UNIT_NAME);

const DesktopNav = () => {
  const { pathname } = useRouter();
  // const linkColor = useColorModeValue('gray.800', 'gray.200');
  const linkHoverColor = useColorModeValue(`brand.500`, `white`);
  const popoverContentBgColor = useColorModeValue(`white`, `gray.800`);

  return (
    <Stack direction={`row`} spacing="8rem" justifyContent="flex-end">
      {navItems.map((navItem) => {
        const isActive =
          (navItem?.href?.includes(`/business/`) && pathname?.includes(`/business/`)) ||
          pathname === navItem?.href;

        log.debug(
          `pathname: ${pathname}, navItem.href: ${navItem?.href}, isActive: ${isActive}`,
        );

        return (
          <Box key={navItem.label}>
            <Popover trigger={`hover`} placement={`bottom-start`}>
              <PopoverTrigger>
                <Link
                  color={isActive && linkHoverColor}
                  href={navItem.href}
                  p={2}
                  textStyle="body"
                  _hover={{ textDecoration: `none`, color: linkHoverColor }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  // rounded='xl'
                  bg={popoverContentBgColor}
                  border="none"
                  borderTop="1px solid #eee"
                  boxShadow="md"
                  minW="100vw"
                  mt="10px"
                  p={4}
                >
                  <HStack
                    borderTop="1px solid gray.200"
                    justify="flex-end"
                    mr={{ 'base': `18.3vw`, '3xl': `29vw` }}
                  >
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </HStack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        );
      })}
    </Stack>
  );
};

export default DesktopNav;

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Link,
  Stack,
  chakra,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import NextLink from 'next/link';
import React from 'react';

import Logo, { LogoIcon } from '@/components/logo';
import { FooterLink, footerLinks } from '@/layout/footer';

import DesktopNav from './desktop-nav';
import MobileNav from './mobile-nav';

import type { HTMLChakraProps } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
export type NavLinkProps = PropsWithChildren<{
  href: string;
  name: string;
}>;

export const NavLink = ({ href, name, children }: NavLinkProps) => {
  const { pathname } = useRouter();
  const isActive = pathname === href;

  return (
    <Link
      aria-label={`${name} 페이지로 이동합니다`}
      color={isActive && `brand.500`}
      display="block"
      h="7"
      href={href}
      transitionProperty="color"
      transitionDuration="normal"
      transitionTimingFunction="ease-in-out"
      w="25"
      _hover={{ color: `brand.500` }}
    >
      {children || name}
    </Link>
  );
};

export type HeaderProps = HTMLChakraProps<'header'>;

const Header = (props: HeaderProps): JSX.Element => {
  const bg = useColorModeValue(`white`, `gray.800`);
  const ref = React.useRef<HTMLHeadingElement>();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <chakra.header
      bg={bg}
      left="0"
      pos="fixed"
      ref={ref}
      right="0"
      shadow="md"
      textStyle="navbar"
      top="0"
      transitionDuration="normal"
      transitionProperty="background-color, box-shadow"
      transitionTimingFunction="ease-in-out"
      width="full"
      zIndex="3"
      {...props}
    >
      <Flex
        align={`center`}
        bg={useColorModeValue(`white`, `gray.800`)}
        height="4.75rem"
        justify="space-between"
        maxW="8xl"
        mx="auto"
        px={{ base: `1.5rem` }}
        py={{ base: 2 }}
      >
        <Flex align="center">
          <NextLink href="/" passHref>
            <chakra.a display="block" aria-label="INNONM, Back to homepage">
              <Logo display={{ base: `none`, md: `block` }} />
              <Box minW="3rem" display={{ base: `block`, md: `none` }}>
                <LogoIcon />
              </Box>
            </chakra.a>
          </NextLink>
        </Flex>

        <Flex
          flex={{ base: 1, md: `auto` }}
          justify="flex-end"
          ml={{ base: -2 }}
          display={{ base: `flex`, md: `none` }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w="18px" h="18px" />
              ) : (
                <HamburgerIcon w="28px" h="28px" />
              )
            }
            variant={`ghost`}
            aria-label={`Toggle Navigation`}
          />
        </Flex>

        <Stack
          direction={`row`}
          display={{ base: `none`, md: `flex` }}
          flex={{ base: 1, md: 0 }}
          justify={`flex-end`}
          // mx="auto"
          mr="7vw"
          spacing={6}
        >
          <DesktopNav />
        </Stack>

        <Stack
          align="center"
          direction={`row`}
          display={{ base: `none`, md: `flex` }}
          flex={{ base: 1, md: 0 }}
          justify={`flex-end`}
          opacity="0.8"
          position="absolute"
          right="3rem"
          spacing={3}
        >
          {footerLinks
            // .filter((link) => link.label !== `Phone` && link.label !== `Email`)
            .map((link) => (
              <FooterLink key={link.href} {...link} />
            ))}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </chakra.header>
  );
};

export default Header;

import { get } from 'lodash';

import uiJson from '_/i18n/ui.json';

export const t = (str: string): string => {
  // TODO: load the locale appropriate ui.json file
  const result: unknown = get(uiJson, str);
  if (typeof result !== `string`) {
    throw new Error(`Translation not found for ${str}`);
  }
  return result;
};

const isStringArray = (test: any[]): boolean => {
  return Array.isArray(test) && !test.some((value) => typeof value !== `string`);
};

export const tl = (str: string): string[] => {
  // TODO: load the locale appropriate ui.json file
  const result = get(uiJson, str);
  // let fail = false;
  // if (!Array.isArray(result)) {
  //   fail = true;
  // } else {
  //   result.forEach((item) => {
  //     if (typeof item !== `string`) {
  //       fail = true;
  //     }
  //   });
  // }
  // if (fail) {
  //   throw new Error(`Translation not found for ${str}`);
  // } else {
  //   return result;
  // }

  if (!isStringArray(result)) {
    throw new Error(`Translation not found for ${str}`);
  } else {
    return result;
  }
};

import { CSSObject, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export type TextUnderlineDividerProps = React.PropsWithChildren<{
  // OPTIONAL
  colorDark?: string;
  colorLight?: string;
  hide?: boolean;
  show?: boolean;
  underlineColorDark?: string;
  underlineColorLight?: string;
  underlineHeight?: CSSObject['height'];
  underlineMarginBottom?: CSSObject[`marginBottom`];
  underlineMarginTop?: CSSObject[`marginTop`];
  underlineWidth?: CSSObject['width'];
}>;

const TextUnderlineDivider = ({
  children,

  // OPTIONAL
  colorDark = `whiteAlpha.900`,
  colorLight = `gray.700`,
  hide = false,
  show = true,
  underlineColorDark = `brand.200`,
  underlineColorLight = `brand.300`,
  underlineHeight = `0.4rem`,
  underlineMarginBottom = `0.75rem`,
  underlineMarginTop = `0.25rem`,
  underlineWidth = `3.5rem`,
}: TextUnderlineDividerProps) => (
  <Flex
    color={useColorModeValue(colorLight, colorDark)}
    flexDir="column"
    position="relative"
    zIndex={0}
    _after={{
      bg: useColorModeValue(underlineColorLight, underlineColorDark),
      content: `""`,
      display: hide && `none`,
      h: underlineHeight,
      mb: underlineMarginBottom,
      mt: underlineMarginTop,
      mx: `auto`,
      opacity: show ? 1 : 0,
      transition: `opacity normal ease-in-out`,
      w: underlineWidth,
      zIndex: -1,
    }}
  >
    {children}
  </Flex>
);

export default TextUnderlineDivider;

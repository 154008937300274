import { Center, Link } from '@chakra-ui/react';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';

export interface NextPageButtonProps {
  href?: string | null;

  // OPTIONAL
  show?: boolean;
}

const NextPageButton = ({
  href = null,

  // OPTIONAL
  show = false,
}: NextPageButtonProps): JSX.Element => {
  const wrapSpring = useSpring({
    display: href && show ? `block` : `none`,
    opacity: href && show ? 0.8 : 0,
  });

  return (
    <animated.div style={wrapSpring}>
      <Link
        type="button"
        href={href}
        pos="fixed"
        bottom={{ base: `100px`, md: `110px` }}
        right={{ base: `20px`, md: `37px` }}
      >
        <Center bg="gray.400" borderRadius="50%" w="50px" h="50px">
          <FaAngleDoubleRight color="blackAlpha.700" size="22px" />
        </Center>
      </Link>
    </animated.div>
  );
};

export default NextPageButton;
